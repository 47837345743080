import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import Sadko from './../service/Sadko';
import { HeaderButtonValue, HeaderLabel } from './layout/style';
import { Harp } from '../context';

const ItemLabel = styled.span`
    font-family: 'Ruslan Display', cursive;
    font-size: 20px;
    font-weight: bold;
`;

const SelectedItemLabel = styled(ItemLabel)`
    color: #aa0000
`;

export default function StringsCountSelector({ useCustomStyles = true }) {
    const { state: { stringsCount }, dispatch } = useContext(Harp.Context);

    const containerRef = useRef();

    const getStringsCountOptions = () => {
        const initialSequence = Sadko.getAvailableStringsCount();
        const currentIndex = initialSequence.indexOf(stringsCount);

        const result = [];
        let index = currentIndex - 2;

        while (result.length < initialSequence.length) {
            if (index < 0) {
                index = initialSequence.length + index;
            }

            if (index > initialSequence.length - 1) {
                index = 0;
            }

            result.push(initialSequence[index]);
            index++;
        }

        return result;
    };

    const stringOptions = getStringsCountOptions();

    const [dimensions, setDimensions] = useState({});

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        setDimensions(containerRef.current.getBoundingClientRect());
    }, []);

    const onChangeStringsCount = (value) => {
        dispatch({ type: Harp.actions.SET_STRINGS_COUNT, count: value });
    };

    const getContainerWidth = () => {
        if (!dimensions) {
            return 0;
        }

        return -104;
    };

    const menu = <Menu style={useCustomStyles ? { marginTop: `${getContainerWidth()}px`, marginLeft: '-12px', zIndex: 15 } : {}}>
        {stringOptions.map((count, index) => {
            return <Menu.Item onClick={() => onChangeStringsCount(count)} count={count} key={`note-${index}`}>
                {!useCustomStyles ? count : (count === stringsCount ? <SelectedItemLabel>{count}</SelectedItemLabel> : <ItemLabel>{count}</ItemLabel>)}
            </Menu.Item>
        })}
    </Menu>;

    return <div>
        <HeaderLabel>Количество струн</HeaderLabel>
        <div ref={containerRef}>
            <Dropdown overlay={menu} trigger={['click']}>
                <HeaderButtonValue>{stringsCount}</HeaderButtonValue>
            </Dropdown>
        </div>
    </div>
}
