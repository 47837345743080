import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import Sadko from './../service/Sadko';
import { Chord } from './Chord';
import { HeaderButton, HeaderButtonValue, HeaderLabel } from './layout/style';
import { Chords, Harp } from '../context';

const TuneSelectorContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 165px;
    padding-right: 16px;
    text-align: center;
`;

const ChordContainer = styled.div`
    margin: 0 auto;
    height: 140px;
    width: 300px;
    @media (max-width: 768px) {
        margin-left: -14px;
    }
`;

const ModalTitle = styled.div`
    font-family: 'Ruslan Display';
`;

const DroneText = styled.div`
    font-family: 'Arsenal', sans-serif;
    display: block;
    margin: -5px;
`;

function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            fn();
        } else {
            didMountRef.current = true;
        }
    }, inputs);
}

export default function TuneSelector({ modal }) {
    const { state: { tune, drone, stringsCount }, dispatch: dispatchHarpContext } = useContext(Harp.Context);
    const { dispatch: dispatchChordsContext } = useContext(Chords.Context);
    const [open, setOpen] = useState(false);
    const [currentTune, setCurrentTune] = useState(tune);
    const [currentDrone, setCurrentDrone] = useState(drone);

    const getCurrentDrone = () => currentDrone ? currentDrone : Sadko.DRONE_NONE;

    const isDroneSelected = () => {
        return [Sadko.DRONE_MAJOR, Sadko.DRONE_MINOR].indexOf(drone) >= 0
    };

    const saveChanges = useCallback(() => {
        dispatchHarpContext({
            type: Harp.actions.SET_TUNE,
            tune: currentTune,
            drone: currentDrone ? currentDrone : Sadko.DRONE_NONE,
        });
        dispatchChordsContext({
            type: Chords.actions.RESET
        });
    }, [currentTune, currentDrone, dispatchHarpContext, dispatchChordsContext]);

    useDidUpdateEffect(() => {
        if (!modal) {
            saveChanges();
        }

    }, [currentTune, currentDrone, modal]);

    const droneOptions = [
        {
            label: 'Нет',
            value: Sadko.DRONE_NONE
        },
        {
            label: 'Мажорный',
            value: Sadko.DRONE_MAJOR
        },
        {
            label: 'Минорный',
            value: Sadko.DRONE_MINOR
        }
    ];

    modal = modal === undefined ? true : modal;

    const openTuneModal = () => {
        setOpen(true);

        return false;
    };

    const closeTuneModal = () => {
        setOpen(false);
    };

    const handleChangeDrone = (value) => {
        setCurrentDrone(value);
    };

    const handleChangeTune = (value) => {
        setCurrentTune(value)
    };

    const saveTune = () => {
        closeTuneModal();
        saveChanges();
    };

    const renderContent = () => {
        const chordProps = {
            tune: currentTune,
            drone: getCurrentDrone(),
            stringsCount: stringsCount
        };

        return <div>
            <Row>
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item label='Тон' colon={false}>
                        <Select
                            style={{ width: 200 }}
                            value={currentTune}
                            onChange={handleChangeTune}
                            inputProps={{
                                id: 'tune-selector'
                            }}
                        >
                            {Sadko.getGamma().map((note, index) => <Select.Option value={note} key={`note-${index}`}>{note}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                    <Form.Item label='Бурдон' colon={false}>
                        <Select
                            style={{ width: 200 }}
                            value={getCurrentDrone()}
                            onChange={handleChangeDrone}
                            inputProps={{
                                id: 'drone-selector'
                            }}
                        >
                            {droneOptions.map((option, index) => <Select.Option
                                    value={option.value}
                                    key={`drone-${index}`}>
                                    {option.label}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <ChordContainer>
                <Chord {...chordProps}/>
            </ChordContainer>
        </div>
    };

    if (!modal) {
        return renderContent();
    }

    return <TuneSelectorContainer>
        <HeaderLabel>
            Строй инструмента
        </HeaderLabel>
        <HeaderButton onClick={openTuneModal}>
            <HeaderButtonValue>{tune}</HeaderButtonValue>
            {isDroneSelected() && <DroneText>с {currentDrone === Sadko.DRONE_MAJOR ? 'мажорным' : 'минорным'} бурдоном</DroneText>}
        </HeaderButton>

        <Modal
            visible={open}
            onClose={closeTuneModal}
            title={<ModalTitle>Строй инструмента</ModalTitle>}
            onOk={saveTune}
            onCancel={closeTuneModal}
            footer={[
                <Button key='back' onClick={closeTuneModal}>
                    Закрыть
                </Button>,
                <Button key='submit' type='primary' onClick={saveTune}>
                    Сохранить
                </Button>,
            ]}
        >
            {renderContent()}
        </Modal>
    </TuneSelectorContainer>
}
