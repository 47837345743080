import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const MenuLink = styled.a`
    text-shadow: 1px 1px 2px #eee;
    color: ${({active}) => active ? 'red': '#aa0000'} !important;
`;

const isLinkActive = (linkUrl, currentRoute) => {
    if (currentRoute === linkUrl) {
        return true;
    }

    return linkUrl.length > 1 && currentRoute.length > 1 && currentRoute.indexOf(linkUrl) === 0;
};

export default function Menu({ links = [] }) {
    const router = useRouter();

    return <ul className='main-menu'>
        {links.map((link, index) => (
            <li key={`link-${index}`}>
                <Link href={link.to} as={link.to} passHref>
                    <MenuLink className='item-link' active={isLinkActive(link.to, router.route)}>{link.title}</MenuLink>
                </Link>
            </li>
        ))}
    </ul>
}
