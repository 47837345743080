import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
    padding-top: 32px;
    position: relative;
`;

const FooterBrands = styled.div`
    font-size: 10px;
    padding-top: 16px;
`;

export const ContentDivider = styled.div`
    height: 30px;
    background: url('/images/content-divider.svg') no-repeat center;
`;

const AuthorBlock = styled.div`
    text-align: center;
    padding: 16px 0;
`;

export default function Footer() {
    return (
        <FooterContainer>
            <ContentDivider/>
            <AuthorBlock>
                &copy; <a href='mailto:alx.devel@gmail.com'>anyx</a><br/>
                <div>{new Date().getFullYear()}</div>

                <FooterBrands>
                <a href='https://iconscout.com/icon/vk-17' rel='noopener noreferrer' target='_blank'>Vk Icon</a> by&nbsp;
                    <a href='https://iconscout.com/contributors/ibrandify-icons' rel='noopener noreferrer' target='_blank'>Ibrandify Icons</a>
                </FooterBrands>
            </AuthorBlock>
        </FooterContainer>
    )
}
