import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, Affix } from 'antd';
import Link from 'next/link';
import StringsCountSelector from '../StringsCountSelector';
import TuneSelector from '../TuneSelector';

const HeaderContainer = styled.div`
`;

const HeaderTitleContainer = styled.div`
`;

const HeaderTitle = styled.div`
    left: 50%;
    width: 120px;
    margin-left: -60px;
    position:relative;
`;

const DrawerTitle = styled.div`
    font-family: 'Ruslan Display', cursive;
    font-size: 22px;
    color: #fff;
    text-shadow: 1px 1px 2px black;
    line-height: 22px;
    margin-top: 5px;
`;

const Title = styled.div`
    font-family: 'Ruslan Display', cursive;
    font-size: 26px;
    margin-top: -7px;
    color: #fff;
    text-shadow: 1px 1px 2px black;
`;

const SubTitle = styled.div`
    padding-left: 4px;
    font-family: 'Neucha', cursive;
    margin-top: -18px;
    margin-left: 26px;
    color: #fff;
    text-shadow: 1px 1px 2px black;
`;

const Menu = styled.ul`
    padding: 0;
    margin: 0 !important;
    list-style:none;
    text-align: left !important;
`;

const MenuItem = styled.li`
    padding: 4px 0 0 0 !important;
    margin: 0;
    display: block !important;
    border-bottom: 1px solid #e2cebd;
    &:last-child {
        border-bottom: none;
    }
`;

const Logo = styled.img`
    max-width: 32px;
    position: absolute;
    top: 12px;
    left: 12px;
`;

const MenuHeaderText = styled.span`
    padding-left: 28px;
`;

export default function MobilePanel({ menu }) {

    const [menuVisible, setMenuVisible] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);

    return <>
        <Affix offsetTop={0}>
            <HeaderContainer className='mobile-header'>
                <Button shape='circle' icon='menu' ghost onClick={() => setMenuVisible(true)}/>
                <HeaderTitleContainer>
                    <HeaderTitle>
                        <Title>Гусли</Title>
                        <SubTitle>для начинающих</SubTitle>
                    </HeaderTitle>
                </HeaderTitleContainer>
                <Button shape='circle' icon='setting' ghost onClick={() => setSettingsVisible(true)}/>
            </HeaderContainer>
        </Affix>
        <Drawer
            title={<DrawerTitle><Logo src='/images/favicon.svg' alt='ruharp'/><MenuHeaderText>Меню</MenuHeaderText></DrawerTitle>}
            placement='left'
            headerStyle={{ height: '55px', backgroundColor: '#cc6101' }}
            closable={false}
            onClose={() => setMenuVisible(false)}
            visible={menuVisible}
        >
            <Menu className='main-menu'>
                {menu.map((link, key) => <MenuItem key={`menu-item-${key}`}>
                    <Link href={link.to}>
                        <a className='item-link' onClick={() => setMenuVisible(false)}>{link.title}</a>
                    </Link>
                </MenuItem>)}
            </Menu>
        </Drawer>

        <Drawer
            title={<DrawerTitle>Настройки</DrawerTitle>}
            placement='right'
            headerStyle={{ height: '55px', backgroundColor: '#cc6101' }}
            closable={true}
            width={320}
            onClose={() => setSettingsVisible(false)}
            visible={settingsVisible}
        >
            <div style={{ paddingBottom: '16px' }}>
                <StringsCountSelector useCustomStyles={false}/>
            </div>
            <TuneSelector modal={false}/>
        </Drawer>
    </>
}
