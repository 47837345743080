import React, { useContext, useEffect } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import TuneSelector from '../TuneSelector';
import Menu from '../Menu';
import StringsCountSelector from '../StringsCountSelector';
import Footer from './Footer';
import { Divider, PageContainer } from './style';
import MobilePanel from './MobilePanel';
import Router from 'next/router';
import { Chords } from '../../context';

const SiteContainer = styled.div`
`;

const getImgSrc = (src) => {
    return src;
};

const links = [
    {
        to: '/',
        title: 'Главная',
    },
    {
        to: '/lessons',
        title: 'Уроки'
    },
    {
        to: '/chords',
        title: 'Аккорды'
    },
    {
        to: '/songs',
        title: 'Песни'
    },
    {
        to: '/masters',
        title: 'Мастерские'
    },
    {
        to: '/music',
        title: 'Музыка'
    },
    {
        to: '/about',
        title: 'О проекте'
    },
];

export default function Layout({ children }) {
    const { dispatch } = useContext(Chords.Context);
    useEffect(() => {
        const handleRouteChange = () => {
            dispatch({ type: Chords.actions.RESET });
        };

        Router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            Router.events.off('routeChangeStart', handleRouteChange)
        }
    }, []);

    return <SiteContainer className='container'>
        <header>
            <div className='header-selector header-selector-left'>
                <StringsCountSelector/>
            </div>
            <div className='header-selector header-selector-right'>
                <TuneSelector/>
            </div>

            <img src={getImgSrc('/images/main-logo.svg')} className='sun' alt=''/>
            <Link href='/'>
                <a className='logo-text'>
                    <h3 className='logo-title'>
                        Гусли
                    </h3>
                    <h4 className='logo-subtitle'>для начинающих</h4>
                </a>
            </Link>
            <MobilePanel menu={links}/>
        </header>

        <section className='menu'>
            <Divider/>
            <Menu links={links}/>
            <Divider/>
        </section>

        <PageContainer>
            {children}
        </PageContainer>

        <Footer/>
        <div dangerouslySetInnerHTML={{
            __html: `
            <!-- Yandex.Metrika counter -->
            <script type="text/javascript" >
               (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
               m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
               (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            
               ym(44068699, "init", {
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true
               });
            </script>
            <noscript><div><img src="https://mc.yandex.ru/watch/44068699" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
            <!-- /Yandex.Metrika counter -->
            `
        }}>
        </div>
    </SiteContainer>
}
