import React from 'react'
import App from 'next/app'
import Head from 'next/head';
import nookies from 'nookies'
import Layout from '../components/layout/Layout';
import { Chords, Harp, Rollbar } from '../context';
import Sadko from '../service/Sadko';
import config from '../config/app.js';
import '../assets/antd.less';
import '../assets/style.less';
import '../assets/fonts.less';
import '../assets/mobile.less';

export default class HarpApp extends App {
    static async getInitialProps({ Component, ctx }) {
        const cookies = nookies.get(ctx);

        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

        return {
            drone: cookies[config.cookie.drone] || Sadko.DRONE_NONE,
            tune: cookies[config.cookie.tune] || 'G',
            stringsCount: parseInt(cookies[config.cookie.stringsCount], 10) || 9,
            pageProps
        }
    }

    render() {
        const { Component, pageProps, drone, tune, stringsCount } = this.props;

        return <Rollbar.Provider>
            <Harp.Provider drone={drone} tune={tune} stringsCount={stringsCount}>
                <Chords.Provider>
                    <Head>
                        <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png"/>
                        <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png"/>
                        <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png"/>
                        <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png"/>
                        <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png"/>
                        <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png"/>
                        <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png"/>
                        <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png"/>
                        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png"/>
                        <link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png"/>
                        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
                        <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png"/>
                        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
                        <link rel="shortcut icon" type="image/x-icon" href="/favicon/favicon.ico"/>
                        <link rel="manifest" href="/manifest.json"/>
                        <meta name="msapplication-TileColor" content="#ffffff"/>
                        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
                        <meta name="theme-color" content="#ffffff"/>
                    </Head>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </Chords.Provider>
            </Harp.Provider>
        </Rollbar.Provider>
    }
}
